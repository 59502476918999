import React, { useEffect } from "react";
import Section from "./components/Section";
import "./App.css";

const App: React.FC = () => {
  useEffect(() => {
    const sections = document.querySelectorAll(".section-container");
    sections.forEach((section) => {
      section.classList.add("fadeIn");
    });
  }, []);

  return (
    <div className="app-container snap-y snap-mandatory overflow-y-scroll h-screen text-slate-100">
      <Section id="home" title="">
        <h1 className="text-6xl text-center">BEHRAD FARAHANI</h1>
        <h2 className="subtitle">EST. 2002</h2>
        <div className="scroll-indicator">
          <div className="pill">
            <div className="dot"></div>
          </div>
          <span>Scroll Down</span>
        </div>
      </Section>
      <Section id="about" title="Work in progress...">
        <p className="text-center mt-4">
          {new Date().toLocaleDateString()}
        </p>
      </Section>
    </div>
  );
};

export default App;
